import React, { useEffect, useState } from 'react'
import Balance from '../../img/balance.png'
import Money from '../../img/money.png'
import Request from '../../img/transaction.png'
import { HiCreditCard, HiGlobeAlt } from 'react-icons/hi'
import { RiMastercardFill } from 'react-icons/ri'
import { IoMdAdd } from 'react-icons/io'
import { SiAdblock } from 'react-icons/si'
import { BiTransferAlt, BiBox } from 'react-icons/bi'
import Increase from '../../img/increase.png';
import Decrease from '../../img/decrease.png';
import { BalanceChart, IncomeChart, ExpenseChart } from './Charts'
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AllTransactionsModal from './AllTransactionsModal'

function Dashboard(props) {

    useEffect(() => {
        // console.log(' u/009')
    }, [props.userData])

    const [showAllTransactions, setShowAllTransactions] = useState(false)


    return (
        <div className='home__dashboard'>
            <AllTransactionsModal visible={showAllTransactions} setVisible={setShowAllTransactions} transactionArray={JSON.parse(props.userData.Transaction)} />
            <div className="home__dashboard__main">
                <div className="home__dashboard__main__previews">
                    <div className="home__dashboard__main__previews__details">
                        <PreviewItem
                            value={formatBalance(props.userData.Balance)}
                            label="Account Balance"
                        >
                            <img src={Balance} alt="Balance Representation" className="home__dashboard__main__previews__details__item__img" />
                        </PreviewItem>

                        <PreviewItem
                            value="9,143"
                            label="Annual Income"
                        >
                            <img src={Money} alt="Money Representation" className="home__dashboard__main__previews__details__item__img" />
                        </PreviewItem>

                        <PreviewItem
                            value="1,187"
                            label="Annual Charges"
                        >
                            <img src={Request} alt="Request Representation" className="home__dashboard__main__previews__details__item__img" />
                        </PreviewItem>
                    </div>

                    <div className="home__dashboard__main__previews__chartsection">
                        <div className="home__dashboard__main__previews__chartsection__chartbox">
                            <h5 className="home__dashboard__main__previews__chartsection__head">Balance</h5>
                            <BalanceChart data={props.userData} />
                        </div>
                        <div className="home__dashboard__main__previews__chartsection__breakdownbox">
                            <div className="home__dashboard__main__previews__chartsection__incomebox">
                                <h5 className="home__dashboard__main__previews__chartsection__head">Income</h5>
                                <IncomeChart />
                            </div>
                            <div className="home__dashboard__main__previews__chartsection__expensebox">
                                <h5 className="home__dashboard__main__previews__chartsection__head">Expense</h5>
                                <ExpenseChart />
                            </div>

                        </div>

                    </div>

                    <div className="home__dashboard__main__previews__limit">

                        <div className="home__dashboard__main__previews__limit__container">
                            <div className="home__dashboard__main__previews__limit__head">
                                <p className="home__dashboard__main__previews__limit__head__text">Credit Limit</p>
                                <HiCreditCard className='home__dashboard__main__previews__limit__head__icon' />
                            </div>
                            <p className="home__dashboard__main__previews__limit__content">1500 <span>/2000 USD</span></p>
                            <div className="home__dashboard__main__previews__limit__viewbox">
                                <div className="home__dashboard__main__previews__limit__viewbox__filled" />
                            </div>
                        </div>

                        <div className="home__dashboard__main__previews__limit__container">
                            <div className="home__dashboard__main__previews__limit__head">
                                <p className="home__dashboard__main__previews__limit__head__text">Online Limit</p>
                                <HiGlobeAlt className='home__dashboard__main__previews__limit__head__icon' />
                            </div>
                            <p className="home__dashboard__main__previews__limit__content">1280 <span>/1500 USD</span></p>
                            <div className="home__dashboard__main__previews__limit__viewbox">
                                <div className="home__dashboard__main__previews__limit__viewbox__filled" />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="home__dashboard__card">
                <div className="home__dashboard__card__container">
                    <p className="home__dashboard__card__head">Your Cards</p>

                    <div className="home__dashboard__card__item">
                        <div className="home__dashboard__card__item__head">
                            <RiMastercardFill className="home__dashboard__card__item__head__logo" />
                            <span>MasterCard</span>
                        </div>
                        <p className="home__dashboard__card__item__name">{props.userData.FullName}</p>
                        <p className="home__dashboard__card__item__accountno">{props.userData.AccountNumber}</p>
                        <p className="home__dashboard__card__item__date">09 / 27</p>
                    </div>

                    <div className="home__dashboard__card__item__btnsection">
                        <button
                            className="btn home__dashboard__card__item__btn home__dashboard__card__item__btn--add"
                            onClick={() => notify()}
                        >
                            <IoMdAdd className="home__dashboard__card__item__btn__label" />
                            <span>Add Card</span>
                        </button>

                        <button className="btn home__dashboard__card__item__btn home__dashboard__card__item__btn--block">
                            <SiAdblock className="home__dashboard__card__item__btn__label" />
                            <span>Block Card</span>
                        </button>
                    </div>
                </div>

                <div className="home__dashboard__card__transactions">
                    <div className="home__dashboard__card__transactions__head">
                        <h5 className="home__dashboard__card__transactions__head__title">Recent Transactions</h5>
                        <BiTransferAlt className="home__dashboard__card__transactions__head__icon" />
                    </div>

                    {
                        renderTransactionPreview(props.userData)
                    }

                    <button
                        className="btn home__dashboard__card__transactions__btn"
                        onClick={() => setShowAllTransactions(true)}
                    >
                        View More
                    </button>

                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

function formatBalance(balance) {
    // Convert the string to a number
    const balanceNumber = Number(balance);
  
    // Format the number with commas and two decimal places
    const formattedBalance = balanceNumber.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  
    return formattedBalance;
  }

const PreviewItem = (props) => {
    return (
        <div className="home__dashboard__main__previews__details__item">
            <div className="home__dashboard__main__previews__details__item__imgbox">
                {props.children}
            </div>
            <div className="home__dashboard__main__previews__details__item__text">
                <p className="home__dashboard__main__previews__details__item__text__value">{`\u20AC ${props.value}`}</p>
                <p className="home__dashboard__main__previews__details__item__text__label">{props.label}</p>
            </div>
        </div>
    )
}

export const TransactionItem = (props) => {
    //console.log(props)
    return (
        <div className="home__dashboard__card__transactions__item">
            <div className="home__dashboard__card__transactions__item__body">
                <div className="home__dashboard__card__transactions__item__iconbox">
                    {
                        props.data.type ?
                            <img src={Increase} alt="Increase Rep" className="home__dashboard__card__transactions__item__icon" /> :
                            <img src={Decrease} alt="Decrease Rep" className="home__dashboard__card__transactions__item__icon" />
                    }

                </div>
                <div className="home__dashboard__card__transactions__item__details">
                    <p className="home__dashboard__card__transactions__item__details__title">{props.data.title}</p>
                    <p className="home__dashboard__card__transactions__item__details__date">{props.data.date}</p>
                    
                </div>
            </div>

            <div className="home__dashboard__card__transactions__item__price">
                <span>{props.data.type ? "+" : "-"}</span>
                <span>&euro;</span>
                <span style={{ fontSize: '1.2rem' }}>{Number(props.data.amt).toLocaleString('en-US')}</span>
                <p>{props.data.status}</p>
            </div>
        </div>
    )
}

const renderTransactionPreview = (props) => {
    //console.log(props)
    const length = JSON.parse(props.Transaction).length
    const transactionArray = JSON.parse(props.Transaction)
    //console.log(transactionArray)

    if (length > 0) {
        //console.log('here >> 0')

        if (length > 2) {
            //console.log('here >> 2')
            return (
                <div className="home__dashboard__card__transactions__body">
                    <TransactionItem
                        data={transactionArray[length - 1]}
                    />

                    <TransactionItem
                        data={transactionArray[length - 2]}

                    />

                    <TransactionItem
                        data={transactionArray[length - 3]}
                    />
                </div>
            )
        } else {
            //console.log('here in between 2')
            transactionArray.reverse()
            return (
                <div className="home__dashboard__card__transactions__body">
                    {
                        transactionArray.map((item, value) => (
                            <TransactionItem
                                data={item}
                            />
                        ))
                    }

                </div>
            )
        }

    } else {
        <div className="home__dashboard__card__transactions__iconbox">
            <BiBox size={50} />
        </div>
    }



}

const notify = (text) => {

    toast.error("Contact our help email for support on adding card", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
    });

};

const mapStateToProps = (state) => ({
    userData: state.current_user.data,
})

export default connect(mapStateToProps)(Dashboard);
